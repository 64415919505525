import React, { useState } from 'react'
import { readFileContent } from '../../app/fileHelper';
import { PriceHistoryEntry, ShopProduct } from '../models/priceModels';
import priceHistoryConverter from '../priceHistoryConverter';
import priceHistoryDataClient from '../priceHistoryDataClient';
import useRouter from '../../app/hooks/useRouter';
import routes from '../../app/components/routes';

type Props = {}

const ImportPriceHistoryFragment: React.FC<Props> = (props) => {

  const [priceHistory, setPriceHistory] = useState<undefined | Array<PriceHistoryEntry>>()

  const handleFileInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const input = event.target
    // @ts-ignore input.files
    if ('files' in input && input.files.length > 0) {
      // @ts-ignore input.files
      const file = input.files[0]

      readFileContent(file)
        .then(content => {
          const history = priceHistoryConverter.fromCsvList(content)
          setPriceHistory(history)
        })

    }
  }

  const routeContext = useRouter()

  const compactShopProductList: Array<{
    shopProductId: string;
    shopName: string;
    entryCount: number
  }> = Array
    .from(new Set((priceHistory || []).map(entry => entry.shopProductId)))
    .map(shopProductId => {
      const entries = priceHistory.filter(entry => entry.shopProductId === shopProductId)

      return {
        shopProductId,
        shopName: entries[0].shopName,
        entryCount: entries.length
      }
    })

  const [selectedPriceListing, setSelectedPriceListing] = useState<undefined | Array<PriceHistoryEntry>>()
  const [selectedShopProduct, setSelectedShopProduct] = useState<undefined | ShopProduct>()

  const handlePriceListingUpdate = (event: any) => {
    const shopProductId = event.target.value
    const listingsSlice = priceHistory.filter(entry => entry.shopProductId === shopProductId)

    const shopProduct = priceHistoryConverter.fromPriceHistoryEntriesToShopProduct(listingsSlice)
    setSelectedShopProduct(shopProduct)
    setSelectedPriceListing(listingsSlice)
  }

  const importSelectedProductAndNavigateToIt = () => {
    priceHistoryDataClient.storeShopProduct(selectedShopProduct)
    routeContext.history.push(routes.getProductPriceHistoryGraphPath(selectedShopProduct.shopProductId))
  }

  return (
    <div>
      <input
        type="file"
        title="Import price history file"
        onChange={handleFileInputChange}
      />

      {priceHistory !== undefined && (
        <section>
          <h2>Shops and price listing for product: {priceHistory[0].productName}</h2>


          {selectedPriceListing !== undefined && (
            <button onClick={importSelectedProductAndNavigateToIt}>Import product</button>
          )}

          <ul
            style={{
              display: 'block',
              columnWidth: '300px'
            }}
          >
            {compactShopProductList.map(compactEntry => {
              const id = `shop_product_listing_${compactEntry.shopProductId}`

              return (
                <li key={compactEntry.shopProductId}>
                  <input
                    id={id}
                    type="radio"
                    name="shopProductId"
                    value={compactEntry.shopProductId}
                    onChange={handlePriceListingUpdate}
                  />
                  <span>&nbsp;</span>
                  <label htmlFor={id}>{compactEntry.shopName}: {compactEntry.entryCount} price listings</label>
                </li>
              )
            })}
          </ul>
        </section>
      )}

      {selectedPriceListing !== undefined && (
        <section>
          <h2>Price data points</h2>

          <button onClick={importSelectedProductAndNavigateToIt}>Import product</button>

          <p>
            <span>Data table for {selectedPriceListing[0].shopName}: </span>
            <span>{selectedPriceListing[0].productName}. </span>
            {selectedPriceListing[0].redirectUrl ? (
              <span>Product redirect link: <a href={selectedPriceListing[0].redirectUrl}>{selectedPriceListing[0].redirectUrl}</a></span>
            ) : (
                <span>No product redirect link registered</span>
              )}
          </p>

          <ul>
            {selectedPriceListing.map((priceEntry, index) => (
              <li key={index}>
                <span>{priceEntry.date}: </span>
                <span>{priceEntry.price} NOK</span>
              </li>
            ))}
          </ul>
        </section>
      )}
    </div>
  )
}

export default ImportPriceHistoryFragment