import { ShopProduct } from './models/priceModels';
import dayjs from 'dayjs';

enum PriceHistoryStorageKey {
  ShopProducts = 'ShopProducts'
}

function getShopProducts(): ShopProduct[] {
  const item = localStorage.getItem(PriceHistoryStorageKey.ShopProducts)

  return item === null ? [] : JSON.parse(item)
}


function storeShopProduct(shopProduct: ShopProduct): void {
  const existingShopProducts = getShopProducts()

  const existingShopProductIndex = existingShopProducts.findIndex(existing => existing.shopProductId === shopProduct.shopProductId)
  if (existingShopProductIndex !== -1) {
    const updatedPricePoints = [...existingShopProducts[existingShopProductIndex].pricePoints]

    shopProduct.pricePoints.forEach(newPoint => {
      const existingPricePointIndex = updatedPricePoints.findIndex(existingPoint => dayjs(existingPoint.date).isSame(dayjs(newPoint.date)))
      if (existingPricePointIndex !== -1) {
        updatedPricePoints[existingPricePointIndex] = newPoint
        return
      }

      updatedPricePoints.push(newPoint)
    })

    const updatedShopProducts = [...existingShopProducts]
    updatedShopProducts[existingShopProductIndex].pricePoints = updatedPricePoints

    const payload: string = JSON.stringify(updatedShopProducts)
    localStorage.setItem(PriceHistoryStorageKey.ShopProducts, payload)

    return
  }

  const payload: string = JSON.stringify([...existingShopProducts, shopProduct])
  localStorage.setItem(PriceHistoryStorageKey.ShopProducts, payload)
}

export default {
  getShopProducts,
  storeShopProduct
}