import React from 'react'
import { ShopProduct } from '../../../price-history/models/priceModels';
import { Link } from 'react-router-dom';
import routes from '../../components/routes';

type Props = {
  products: ShopProduct[]
}

const ProductsScreen: React.FC<Props> = (props) => {
  return (
    <div>
      <ul>
        {props.products.map(product => (
          <li style={styles.productItem} key={product.shopProductId}>
            <span style={styles.shopName}>{product.shopName}</span>
            <span style={styles.productName}>{product.productName} </span>
            <Link style={styles.link} to={routes.getProductPriceHistoryGraphPath(product.shopProductId)}>Show price history</Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const styles = {
  productItem: {
    paddingTop: '12px',
    paddingBottom: '12px',
    borderBottom: '1px solid #ccc'
  },
  shopName: {
    fontWeight: 700
  },
  productName: {
    display: 'block'
  },
  link: {
    display: 'block'
  }
}

export default ProductsScreen