import React from 'react'
import { BrowserRouter, Route, Link, Switch } from 'react-router-dom';
import ImportPriceHistoryFragment from '../../price-history/components/ImportPriceHistoryFragment';
import priceHistoryDataClient from '../../price-history/priceHistoryDataClient';
import ProductsScreen from '../products/components/ProductsScreen';
import ProductScreen from '../products/components/ProductScreen';
import routes from './routes';

export enum DisplayMode {
  StoredPriceHistory,
  Import,
  Prices
}

type Props = {}

const App: React.FC<Props> = (props) => {
  const shopProducts = priceHistoryDataClient.getShopProducts()

  return (
    <BrowserRouter>
      <div>
        <header>
          <h1>Prishistorikk</h1>

          <nav style={styles.navWrapper}>
            <Link style={styles.navItem} to="/">Products</Link>
            <Link style={styles.navItem} to={routes.getProductImportPath()}>Import</Link>
          </nav>
        </header>

        <Switch>
          <Route path="/import" render={() => (
            <ImportPriceHistoryFragment />
          )} />

          <Route path={routes.getProductPath(':productId')} render={(props) => {
            const products = priceHistoryDataClient.getShopProducts()
            const { productId } = props.match.params

            if (productId === undefined) {
              return null
            }

            const product = products.find(product => product.shopProductId === productId)

            if (product === undefined) {
              return null; // TODO 404
            }

            return (
              <ProductScreen product={product} />
            )
          }} />

          <Route path="/" exact render={() => (
            <ProductsScreen
              products={shopProducts}
            />
          )} />
        </Switch>
      </div>
    </BrowserRouter>
  )
}

const styles = {
  navWrapper: {
    display: 'flex'
  },
  navItem: {
    padding: '4px 10px',
    marginTop: 0,
    marginBottom: 0,
    marginLeft: '8px',
    marginRight: '8px'
  }
}

export default App