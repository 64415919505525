import { PriceHistoryEntry, ShopProduct, PricePoint } from './models/priceModels';

function fromCsvList(csvContent: string): Array<PriceHistoryEntry> {
  const lines = csvContent.split('\n')
  lines.shift(); // drop header line

  return lines.map(line => {
    const [
      shopProductId,
      shopName,
      productName,
      date,
      price,
      redirectUrl
    ] = line.split('|')

    return {
      shopProductId,
      shopName,
      productName,
      date,
      price: parseInt(price, 10),
      redirectUrl
    }
  })
}

function fromPriceHistoryEntriesToShopProduct(priceHistoryEntries: PriceHistoryEntry[]): ShopProduct {
  const {
    shopProductId,
    productName,
    shopName,
    redirectUrl
  } = priceHistoryEntries[0]

  const pricePoints: PricePoint[] = priceHistoryEntries
    .filter(entry => entry.shopProductId === shopProductId)
    .map(entry => ({
      date: entry.date,
      price: entry.price
    }))

  return {
    shopProductId,
    shopName,
    productName,
    redirectUrl,
    pricePoints
  }
}

export default {
  fromCsvList,
  fromPriceHistoryEntriesToShopProduct
}