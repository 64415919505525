import React from 'react'
import { PricePoint } from '../models/priceModels';
import dayjs from 'dayjs';

type Props = {
  prices: Array<PricePoint>
}

const PriceHistoryTableWidget: React.FC<Props> = (props) => {

  const getDifference = (index: number): number => {
    const entry = props.prices[index]

    const earlierDateEntry = props.prices.length > index ? props.prices[index + 1] : undefined

    // entry is earliest price
    if (earlierDateEntry === undefined) {
      return entry.price
    }

    const difference = entry.price - earlierDateEntry.price
    return difference
  }

  return (
    <div style={{marginLeft: '40px', marginTop: '24px'}}>
      <table style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr style={styles.row}>
            <th>Date</th>
            <th>Price</th>
            <th>Difference</th>
          </tr>
        </thead>
        <tbody>
          {props.prices.map((entry, index) => (
            <tr key={index} style={styles.row}>
              <td style={styles.cell}>{dayjs(entry.date).format('YYYY-MM-DD')}</td>
              <td style={styles.cell}>{entry.price} NOK</td>
              <td style={styles.cell}>{getDifference(index)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const styles = {
  table: {
    borderCollapse: 'collapse'
  },
  row: {
    height: '40px',
    borderBottom: '1px solid #ccc'
  },
  cell: {
    paddingRight: '24px'
  }
}

export default PriceHistoryTableWidget