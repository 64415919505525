import React, { useState, useRef } from 'react'
import {
  VictoryChart,
  VictoryAxis,
  VictoryZoomContainer,
  VictoryLine,
  VictoryBrushContainer,
  DomainPropType,
  VictoryZoomContainerProps,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip
} from 'victory';
import { PricePoint } from '../models/priceModels';
import graphHelper from '../graphHelper';
import dayjs from 'dayjs';
import useComponentSize from '@rehooks/component-size'

type Props = {
  prices: Array<PricePoint>
}

type DataPoint = {
  y: number;
  x: Date;
}

const PriceHistoryGraphWidget: React.FC<Props> = (props) => {
  // should sort?
  const data: Array<DataPoint> = props.prices
    .sort((a, b) => a.date.localeCompare(b.date))
    .map(entry => ({
      x: new Date(entry.date),
      y: entry.price
    }))

  // const dateTickFormat = (tick: Date, index: number, ticks: Date[]): string => {
  //   const date = tick
  //   return `${months[date.getMonth()]} ${date.getFullYear()} `
  // }

  // const xTickStart = graphHelper.getStartOfMonth(data[0].x)
  // const xTickEnd = graphHelper.addMonth(data[data.length - 1].x)
  // const yValues = data.map(point => point.y)
  // const yDomain = [Math.min(...yValues), Math.max(...yValues)]

  const [selectedDomain, setSelectedDomain] = useState<DomainPropType>()

  const [zoomDomain, setZoomDomain] = useState<{ x: [number, number] }>()

  const handleBrush = (domain: { x: [number, number] }, props: VictoryZoomContainerProps) => {
    setZoomDomain({ x: domain.x })
  }

  // const xTicks = graphHelper.getMonthDateTicks(xTickStart, xTickEnd)

  const graphWrapper = useRef(null)
  const size = useComponentSize(graphWrapper)
  const width = size.width


  const lastDataPoint = [...data].pop()
  const dynamicTodayPoint = {
    x: new Date(),
    y: lastDataPoint.y
  }

  return (
    <div style={{
      paddingLeft: '40px',
      paddingRight: '40px'
    }}>
      <div>
        <p>
          <span>Dotted line indicates price from the latest imported price to the current date. </span>
          <span>The imported price may not be the latest price change from the source. </span>
        </p>
      </div>

      <div
        ref={graphWrapper}
      >

        <div>
          <VictoryChart
            theme={VictoryTheme.material}
            width={width}
            height={150}
            scale={{ x: "time" }}
            domainPadding={{ y: 20, x: 20 }}
            containerComponent={
              <VictoryBrushContainer
                responsive
                allowDrag
                allowResize
                brushDimension="x"
                brushDomain={selectedDomain}
                onBrushDomainChange={handleBrush}
              />
            }
          >
            <VictoryLine
              interpolation="stepAfter"
              data={data}
            />

            <VictoryLine
              interpolation="stepAfter"
              data={[lastDataPoint, dynamicTodayPoint]}
              style={{
                data: {
                  strokeDasharray: '5,5'
                }
              }}
            />

            <VictoryAxis
              crossAxis
            />
          </VictoryChart>
        </div>

        <div>
          <VictoryChart
            theme={VictoryTheme.material}
            width={width}
            height={600}
            scale={{ x: "time", y: "linear" }}
            domainPadding={{ y: 20, x: 20 }}
            containerComponent={
              <VictoryZoomContainer
                responsive
                allowZoom={false}
                allowPan={true}
                zoomDimension="x"
                zoomDomain={zoomDomain}
              />
            }
          >
            <VictoryLine
              interpolation="stepAfter"
              data={data}
            />

            <VictoryLine
              interpolation="stepAfter"
              data={[lastDataPoint, dynamicTodayPoint]}
              style={{
                data: {
                  strokeDasharray: '5,5'
                }
              }}
            />

            <VictoryAxis
              dependentAxis
            // tickValues={yDomain}
            />

            <VictoryAxis
              crossAxis
            />

            <VictoryScatter
              data={data}
              size={6}
              labels={(datum: DataPoint) => `${datum.y} NOK\ndate: ${dayjs(datum.x).format('YYYY-MM-DD')}`}
              labelComponent={<VictoryTooltip />}
            />

            <VictoryScatter
              data={[dynamicTodayPoint]}
              size={6}
              style={{
                data: {
                  strokeWidth: 2,
                  stroke: '#000000',
                  fill: '#fff'
                }
              }}
              labels={(datum: DataPoint) => `~TODAY~\n(extrapolated data)\n${datum.y} NOK\ndate: ${dayjs(datum.x).format('YYYY-MM-DD')}`}
              labelComponent={<VictoryTooltip />}
            />
          </VictoryChart>
        </div>

      </div>
    </div>
  )
}

const months = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'des',
]

export default PriceHistoryGraphWidget