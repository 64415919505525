import React from 'react'
import { ShopProduct } from '../../../price-history/models/priceModels';
import { Switch, Route, Link } from 'react-router-dom';
import routes from '../../components/routes';
import PriceHistoryTableWidget from '../../../price-history/components/PriceHistoryTableWidget';
import PriceHistoryGraphWidget from '../../../price-history/components/PriceHistoryGraphWidget';

type Props = {
  product: ShopProduct;
}

const ProductScreen: React.FC<Props> = (props) => {
  const prices = props.product.pricePoints.map(point => point.price)

  return (
    <div>
      <header>
        <h2>{props.product.productName}</h2>
        <p>Shop: {props.product.shopName}</p>

        {props.product.redirectUrl ? (
          <p><a href={props.product.redirectUrl}>{props.product.redirectUrl}</a></p>
        ) : (
            <span>No redirect url registered</span>
          )}

        <nav>
          <Link style={styles.linkItem} to={routes.getProductPriceHistoryGraphPath(props.product.shopProductId)}>Show graph</Link>
          <Link style={styles.linkItem} to={routes.getProductPriceHistoryTablePath(props.product.shopProductId)}>Show table</Link>
        </nav>
      </header>

      <aside>
        <p>Lowest / highest price: {Math.min(...prices)} / {Math.max(...prices)}</p>
      </aside>

      <Switch>
        <Route path={routes.getProductPriceHistoryTablePath(props.product.shopProductId)} render={() => (
          <PriceHistoryTableWidget prices={props.product.pricePoints} />
        )} />

        <Route path={routes.getProductPriceHistoryGraphPath(props.product.shopProductId)} render={() => (
          <PriceHistoryGraphWidget prices={props.product.pricePoints} />
        )} />
      </Switch>
    </div >
  )
}

const styles = {
  linkItem: {
    paddingTop: '4px',
    paddingBottom: '4px',
    paddingLeft: '4px',
    paddingRight: '4px',
    marginLeft: '8px',
    marginRight: '8px'
  }
}

export default ProductScreen