function getProductImportPath() {
  return '/import'
}

function getProductPath(productId: string): string {
  return `/product-id:${productId}`
}

function getProductPriceHistoryGraphPath(productId: string): string {
  return `${getProductPath(productId)}/price-history/graph`
}

function getProductPriceHistoryTablePath(productId: string): string {
  return `${getProductPath(productId)}/price-history/table`
}


export default {
  getProductPath,
  getProductPriceHistoryGraphPath,
  getProductPriceHistoryTablePath,
  getProductImportPath
}